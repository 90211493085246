/* 
EN: This file is generated automatically, don't edit manually. Please modify app-dependant.json if necessary and then restart app, this file will be updated automatically.
VI: File này được sinh tự động, đừng sửa trực tiếp vào đây, nếu cần thì hãy sửa file app-dependant.json sau đó chạy lại app, file này sẽ được tự động update theo.
*/

export class AppConst {
  public static readonly Version: string = "0.6.16";
  public static readonly BuildWhen: string = "2024-06-11 15:01:20";
  public static readonly devFeatureBranch: string = "feature/WPD-2414-add-ability-to-send-counter-bid-via-email-or-sms";
  public static readonly devFeatureBuildBy: string = "Tran Nam Duong <tnduongwarp@gmail.com>";
  public static readonly gaTrackingId: string = "";
  public static readonly devFeature: string = "";
}
